import React, { useState } from "react";
import { graphql } from "gatsby";
import { Page } from "../components/styles";
import Product from "../components/Product";
import styled from "styled-components";
import { device, border, glass } from "../utils/utils";

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.singleProduct ? "1f" : "1fr 1fr")};

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.mobileL} {
    grid-template-columns: 1fr;
  }
`;
const FilterLinks = styled.ul`
  display: block;
  padding-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1.5;

  &:-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    display: flex;
    max-width: 100vw;
    padding: 1.5rem;
    margin-bottom: 1rem;
    overflow: scroll;
    border-bottom: ${border};
    border-color: ${(props) => props.theme.stoneBlack};
    position: sticky;
    top: 42px;
    ${glass};
    background: ${(props) => props.theme.glass};
    z-index: 2;
  }

  li {
    display: inline;
    margin-right: 2rem;
    color: ${(props) => props.theme.stoneBlack};
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    @media ${device.tablet} {
      padding-right: 2.5rem;
      margin-right: 0;
    }
  }
  .active {
    opacity: 1;
  }
`;

const Products = ({ data, setShopFinderOpen }) => {

  const {
    sanityProductsPage: { products: allProducts },
  } = data;
  const [products, setProducts] = useState(allProducts);
  const categories = [
    "All",
    ...new Set(allProducts.map((product) => product.category.name)),
  ];
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filterProducts = (category) => {
    if (category === "All") {
      setSelectedCategory("All");
      setProducts(allProducts);
      return;
    }
    const filteredProducts = allProducts.filter(
      (product) => product.category.name === category
    );
    setSelectedCategory(category);
    setProducts(filteredProducts);
  };

  return (
    <Page>
      <FilterLinks>
        {categories.map((category, index) => (
          <li
            key={index}
            className={category === selectedCategory ? "active" : ""}
            onClick={() => filterProducts(category)}
          >
            {category}
          </li>
        ))}
      </FilterLinks>

      <ProductWrapper singleProduct={products.length === 1}>
        {products.map((product, index) => (
          <Product key={product.id} product={product} setShopFinderOpen={setShopFinderOpen} />
        ))}
      </ProductWrapper>
    </Page>
  );
};

export default Products;

export const query = graphql`
  {
    sanityProductsPage {
      products {
        id
        ingredients
        name
        netWeight
        properUrl
        description
        category {
          name
          id
        }
        _rawImages(resolveReferences: { maxDepth: 10 })
        images {
          alt
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
            metadata {
              palette {
                dominant {
                  background
                  foreground
                }
              }
            }
          }
        }
      }
    }
  }
`;
// ...GatsbySanityImageFluid
