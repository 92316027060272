import React from "react";
import ImageSlider from "../components/ImageSlider";
import styled from "styled-components";
import { border, device } from "../utils/utils";
import { fadeUp } from "../components/styles";

const ProductContainer = styled.div`
  border-top: ${border};
  border-right: ${border};
  border-color: ${(props) => props.theme.stoneBlack};

  :nth-child(even) {
    border-right: none;
  }

  @media ${device.tablet} {
    border: none;
  }
`;
const ProductWrapper = styled.article`
  ${fadeUp}
  padding:4rem;
  max-width: 700px;
  margin: auto;

  img:hover {
    filter: none;
  }

  @media ${device.tablet} {
    padding: 0rem 1rem;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  footer {
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
      padding: 0.5rem;
      margin-bottom: 1rem;
    }

    h2 {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      @media ${device.tablet} {
        margin-top: 1rem;
      }
    }
    h4 {
      line-height: 1.5;
    }
    p {
      margin-top: 1rem;
      text-transform: none;
      line-height: 1.5;
    }
    div {
      display: grid;
      grid-template-columns: 0.5fr 1fr;

      border-bottom: ${border};
      border-top: ${border};
      border-color: ${(props) => props.theme.stoneBlack};
      h3,
      h4 {
        margin: 0;
        padding: 1rem 1rem;
        :first-child {
          border-right: ${border};
          border-color: ${(props) => props.theme.stoneBlack};
          padding-left: 0;
        }
      }
    }
  }
`;
const SliderContainer = styled.div`
  height: 400px;

  @media ${device.laptop} {
    height: 350px;
  }

  @media ${device.tablet} {
    height: 300px;
  }
`;

const Product = ({ product, setShopFinderOpen }) => {
  return (
    <ProductContainer>
      <ProductWrapper>
        <SliderContainer>
          <ImageSlider
            borderRadius={"6px"}
            images={product._rawImages}
            imageWidth={500}
          />
        </SliderContainer>
        <div>
          <footer>
            <h2>
              {product.name}
              <button onClick={() => setShopFinderOpen(true)} inline>
                Buy
              </button>
            </h2>
            <div>
              <h3>{product.netWeight}</h3>
              <h4>{product.ingredients}</h4>
            </div>
            <p>{product.description}</p>
          </footer>
        </div>
      </ProductWrapper>
    </ProductContainer>
  );
};

export default Product;
